<template>
  <div class="container has-text-left">
    <p class="title">Yo</p>

    <form @submit.prevent="submitData">
      <b-field grouped>
        <b-field label="Navn" expanded>
          <b-input v-model="form.name" />
        </b-field>
        <b-field label="Telefonnummer" expanded>
          <b-input v-model="form.phone" />
        </b-field>
      </b-field>
      <b-field label="E-postadresse">
        <b-input type="email" v-model="form.email" />
      </b-field>
      <b-field grouped>
        <b-field label="Registreringsnummer" expanded>
          <b-input v-model="form.regnr" />
        </b-field>
        <b-field label="Kilometerstand" expanded>
          <b-input v-model="form.mileage" />
        </b-field>
      </b-field>

      <b-input v-model="form.extra" />

      <b-button native-type="submit" value="Send inn">Send inn</b-button>
    </form>
  </div>
</template>

<script>
export default {
  name: "FormInquiry",
  data() {
    return {
      form: {
        name: null,
        phone: null,
        email: null,

        regnr: null,
        mileage: null,
        extra: null,

        // autosys
        lastPKK: null,
        lastService: null,
        gearType: null,
        driveType: null
      }
    };
  },
  methods: {
    submitData() {
      console.log("yo");
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 50%;
}
</style>
